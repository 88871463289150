<template>
    <b-card title="Associer un film à une campagne">
        <b-form-group id="selectCampaign" label="campaign : " label-for="selectCampaign">
            <b-row>
                <b-col cols="7">
                    <b-form-select id="selectCampaign" v-model="selectedCampaign" :options="availableCampaign" @change="change"></b-form-select>
                </b-col>
                <b-col cols="5">
                    <div v-for="(i,idx) in creativeMaxAmount" :key="i">
                        <b-form-input v-model="creativeIds[idx]" style="margin-bottom: 5px" placeholder="PubId..."></b-form-input>
                    </div>
                </b-col>
            </b-row>
            <b-row style="display: flex; justify-content: center" v-if="spinner">
                <b-spinner style="width: 4rem; height: 4rem; color: #7336f0" label="Large Spinner"></b-spinner>
            </b-row>
        </b-form-group>
        <b-row class="mt-2 mr-0 justify-content-end" v-if="selectedCampaign">
            <b-button variant="success" @click="associateCreativeToCampaign()">Associer le film</b-button>
        </b-row>
    </b-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                creativeMaxAmount: 6,
                spinner: false,
                selectedCampaign: ''
            };
        },
        computed: {
            ...mapGetters({
                availableCampaignComplete: 'app/getCreativeCamps',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            availableCampaign() {
                return this.availableCampaignComplete.map((c) => {
                    return {
                        text: '[ ' + c.pointCode + ' ] - ( ' + c.startDate + '=>' + c.endDate + ' ) - ' + c.code,
                        value: c.code,
                    };
                });
            },
            creativeIds() {
                const foundCamp = this.availableCampaignComplete.find(camp => camp.code == this.selectedCampaign)
                const pubIds = foundCamp?.pubIds ? foundCamp.pubIds : [];
                return pubIds
            }
        },
        async mounted() {
            await this.getCreativeNeededCampaign();
        },
        methods: {
            ...mapActions('app', ['getCreativeNeededCampaign', 'associateCreative', 'mutateCreativeCamps']),

            async associateCreativeToCampaign() {
                try {
                    // Update campaign details with creattive ID
                    this.spinner = true;
                    const creativeData = {
                        pubIds: this.creativeIds.filter( ci => ci != ''),
                    };

                    const associatedCreative = await this.associateCreative({ campCode: this.selectedCampaign, data: creativeData });

                    //Update state with new Campaign
                    for (const updatedCamp of associatedCreative.data.adcleekUpdate) {
                        await this.mutateCreativeCamps(updatedCamp);
                    }

                    this.spinner = false;
                    this.$toast(this.successToast(`${associatedCreative.data.associationRes[0].OKCreativeIds.length} / ${creativeData.pubIds.length} Creatives ajoutés à la campagne ${this.selectedCampaign} avec succès !`));

                } catch (error) {
                    this.spinner = false;
                    console.log(error);
                    this.$toast(this.errorToast('Erreur lors de la mofication du template'));
                }
            },

            change(value) {
                let camp = { creativeId: '' };
                if (this.selectedCampaign != '') camp = this.availableCampaignComplete.find((c) => c.code == this.selectedCampaign);
                this.creativeId = camp.creativeId;
            }
        },
    };
</script>

<style></style>
